/* validations http status code | khaled Mofeed | 12/12/2022 */
export const VALIDATION_STATUS = 422;
/* async time | khaled Mofeed | 22/12/2022 */
export const ASYNC_TIME = 800;
/* http post method | khaled Mofeed | 12/12/2022 */
export const HTTP_POST_METHOD = "post";
/* http get method | khaled Mofeed | 12/12/2022 */
export const HTTP_GET_METHOD = "get";
/* http get method | khaled Mofeed | 12/12/2022 */
export const HTTP_DELETE_METHOD = "delete";
/* http page no | khaled Mofeed | 9/1/2023 */
export const HTTP_PAGE_No = 0;
/* http per page | khaled Mofeed |  9/1/2023 */
export const HTTP_PER_PAGE = 100;
/* http unit of time | khaled Mofeed |  9/1/2023 */
export const HTTP_UNIT = "hour";
/* http unit of time | khaled Mofeed |  9/1/2023 */
export const HTTP_DAYS = "day";
/* http  Active | khaled Mofeed |  9/1/2023 */
export const HTTP_ACTIVE = "Active";
/* http In Active | khaled Mofeed |  9/1/2023 */
export const HTTP_IN_ACTIVE = "In Active";
/* http  Live | khaled Mofeed |  9/2/2023 */
export const HTTP_LIVE = "Live";
/* http percentage | khaled Mofeed |  9/1/2023 */
export const HTTP_PERCENTAGE = 100;
/* http equal range | khaled Mofeed |  9/1/2023 */
export const HTTP_EQUAL_RANGE = 50;
/* http compleete range | khaled Mofeed |  9/1/2023 */
export const HTTP_COMLEETE_RANGE = 100;
/* connect metamask method | khaled Mofeed | 26/12/2022 */
export const CONNECT_METAMASK = window.ethereum;
/* async time to get data | khaled Mofeed | 26/12/2022 */
export const TIME_TO_GET_DATA = 100;

/* amount for revenu char | khaled Mofeed | 21/2/2023 */
export const AMOUNT_CHART = 2200.2;
/* Time To run effect | khaled Mofeed | 21/2/2023 */
export const EFFECT_TIME = 3000;

/* async time to get data | khaled Mofeed | 26/12/2022 */
export const CLOSE_ICON = "/img/close.svg";
/* default name of seller | khaled Mofeed | 21/2/2023 */
export const ADMIN = "Khaled Mofed";
/* nfts of section A params | khaled Mofeed | 1/3/2023 */
export const A_SECTION = `?location[0]=A&per_page=42`;
/* nft of section B params | khaled Mofeed | 1/3/2023 */
export const B_SECTION = `?location[0]=B&per_page=49`;
/* nft of section C params | khaled Mofeed | 1/3/2023 */
export const C_SECTION = `?location[0]=C&per_page=49`;
/* nft of section D params | khaled Mofeed | 1/3/2023 */
export const D_SECTION = `?location[0]=D&per_page=49`;
/* nft of section E params | khaled Mofeed | 1/3/2023 */
export const E_SECTION = `?location[0]=E&per_page=49`;
/* nft of section F params | khaled Mofeed | 1/3/2023 */
export const F_SECTION = `?location[0]=F&per_page=49`;
/* nft of section G params | khaled Mofeed | 1/3/2023 */
export const G_SECTION = `?location[0]=G&per_page=49`;
// initial adress
export const INITIAL_ADDRESS = "0x0000000000000000000000000000000000000000";
export const OUR_ADDRESS = "0xe08360e5CEfB44E56144ccf2b09c36B12D50E241";

export const digitalShareTabs = {
  PROPOSAL: "proposal",
  REVENUE: "revenue",
};
